.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.card{
  background-color: #0c001a3b;
  animation: slideIn 1s ease-out;
  visibility: hidden;
  /* background-color: #1a1a1d; */

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 55%;
  margin: 20px;
  position:relative;
  border-radius: 20px;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  transform: scale(1.02);
}
.card-text{

  /* border-spacing: 10px; */
  box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  padding: 10px;
  font-size: 1vw;
  width: 95%;
  margin: 5;
  margin-right: 5;
  padding-right: 5;
  text-align: left;  
  /* text-align: right; */
} 
.card-title{ 
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  font-size: auto;
  box-shadow: #282c34;
  color: white;
  text-align: center;
}
.card-body{
  padding: 20px;
  width: 100%;
  height: 100%;
}
.card-image{

  width: 50%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;

} 
.card-subtitle {
  font-size: 0.5em;
  color: darkgray;
  text-align: right;
  padding-right: 30px;
  padding-bottom: 0%;
}
.card-link{

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}
@media screen and (max-width: 600px) {
  .card {
    width: 90%; /* adjust card width for small screens */
  }
}


/* KeyFrames animation */

@keyframes slideIn {
    0% {
        transform: translateX(-50%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}